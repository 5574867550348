import React from 'react';
import styled from 'styled-components';

import { Link, ProductAccordionSection } from '../common';
import { Paragraph, Subtitle } from '../common/product-accordion-section';
import { SiteUrls } from '../../utils/constants';
import { Accordion } from '../../types/component';

const AccordionSection = styled(ProductAccordionSection)``;

const data: Accordion[] = [
  {
    title: 'Frequently Asked Questions',
    body: (
      <>
        <Subtitle>
          What sets Woosa mattresses apart from other brands in terms of quality
          and comfort?
        </Subtitle>
        <Paragraph>
          Woosa&apos;s mattress shop in Singapore goes beyond ordinary sleep
          solutions, creating a place where comfort and quality come together
          beautifully. Notably, our mattresses are made with the superior skill
          of Belgium craftsmanship, a world leader in foam production
          technology. Unlike ordinary mattresses, a Woosa mattress boasts a
          4.5cm layer of latex — a substantial difference compared to the meagre
          1cm typically offered by other brands. This is only part of the story,
          though. Woosa's true strength lies in our carefully balanced mix of
          premium materials, including Sonocore Latex, Woosa Cloud Foam, and
          Polyester Blended Fabric. Our design process is also thoughtful and
          meticulous, sourcing the highest quality foams, including latex,
          memory foam, and base foams, from Belgium. We carefully calibrate each
          layer to the perfect thickness, amplifying the benefits of each
          material, yielding a sleep experience that's plush, supportive, yet
          astoundingly affordable. <br />
          <br />
          It's not just about the luxury of our Italian fabric or the
          combination of materials. It's about the thoughtful design and
          commitment to quality that goes into every square inch of our
          mattresses.
        </Paragraph>

        <Subtitle>
          What is Woosa&apos;s history and expertise in the mattress industry?
        </Subtitle>
        <Paragraph>
          Starting from a single mission to redefine how people sleep, Woosa
          collaborates with renowned foam producers in Belgium and some of the
          best mattress craftsmen in Europe to craft our range of mattresses. We
          combine centuries-old Belgian manufacturing excellence with innovative
          design to cater to the specific needs of Singaporeans. As a prominent
          mattress shop, we&apos;ve steadfastly committed to producing
          mattresses that resonate with the intricate preferences of our
          consumers. And our evolution to a trusted mattress manufacturer
          testifies to our competence in the industry.
          <br />
          <br />A hallmark of our expertise is the development of premium
          Sonocore® latex, used alongside our proprietary Woosa Cloud Foam.
          Harnessing state-of-the-art foam production technologies like
          microwave technology, rather than the traditional "baking" process,
          Sonocore Latex achieves an optimal open-cell consistency. As a result,
          it not only surpasses conventional expectations, but also traditional
          Dunlop latex in terms of durability, comfort, and support, offering an
          extra thick, bouncy, and firm base that provides unrivalled spinal
          support. Moreover, we meticulously calibrate each layer to the correct
          thickness to prevent a "sagging" feel after long-term use. This
          ultra-durable latex, integral to all Woosa&apos;s mattresses, is also
          backed by a 10-year warranty for assured quality.
          <br />
          <br />
          Moreover, Woosa&apos;s expertise extends beyond product development to
          consumer experience. With a mission to simplify the mattress shopping
          experience in Singapore, we at Woosa have taken strides to ensure that
          choosing the perfect sleep solution is as easy as it gets. As such,
          we&apos;ve conceptualised the &apos;Sleep Lab&apos; — a sanctuary
          where you can test our products in a relaxed, stress-free setting.
        </Paragraph>

        <Subtitle>What to expect from Woosa&apos;s mattress showroom?</Subtitle>
        <Paragraph>
          With Woosa, shopping for a mattress becomes as easy as crawling into
          bed — it&apos;s an experience of relaxation and discovery.
          <br />
          <br />
          When you visit Woosa&apos;s mattress showroom in Singapore, you step
          into a world where your comfort is the priority. Unlike traditional
          bed and mattress shops, our Sleep Lab is a sanctuary that enables you
          to try out our mattresses and bedding accessories in a serene,
          pressure-free environment. We&apos;ve reimagined the experience of
          buying a mattress to be as tranquil and relaxed as the sleep
          you&apos;ll enjoy on our products.
          <br />
          <br />
          You can lie on our mattresses, nestle into our pillows, and even test
          our <Link to={SiteUrls.AdjustableBase2}>Adjustable Bed Bases</Link> to
          get a comprehensive feel for our products. Here, you&apos;re not
          rushed or pressured — you&apos;re invited to take your time to
          discover the Woosa difference.
        </Paragraph>

        <Subtitle>Are Woosa mattresses recommended by sleep experts?</Subtitle>
        <Paragraph>
          At Woosa, we take a consultative approach to our mattress design,
          working closely with professionals in the physiotherapy and
          chiropractic industry. This collaboration allows us to develop and
          test our mattresses to ensure they optimise proper spinal alignment
          and sleeping posture without compromising on comfort. Our steadfast
          commitment to aligning our designs with scientific sleep principles
          allows us to deliver products that cater to a variety of sleep
          preferences. This assurance of quality and care is why we are one of
          the top choices in Singapore.
        </Paragraph>

        <Subtitle>Which mattress (Original/Terra/Mysa) should I get?</Subtitle>
        <Paragraph>
          Choosing the right Woosa mattress involves understanding your personal
          sleep needs and matching them with the unique characteristics of our
          mattress offerings. The Woosa Original is perfect for back or side
          sleepers or those trying a full-foam mattress for the first time. Its
          signature 4-layer combination of premium Sonocore® Latex and
          European-made materials provides a medium-firm comfort level. For
          those desiring a firmer surface, the Woosa Terra mattress is our
          recommendation. It&apos;s ideal for back sleepers or heavy side
          sleepers, particularly those transitioning from a firm spring
          mattress. The Woosa Terra also offers double layers of 100% Natural
          Latex for maximum support and posture alignment. For side, stomach or
          light-back sleepers accustomed to a plush, hotel-style feel, the Woosa
          Mysa is a perfect match. Crafted with our proprietary Woosa Cloud
          Foam, the Mysa offers a medium-plush sleeping surface that&apos;s
          reminiscent of high-end memory foam or plush spring mattresses.
        </Paragraph>

        <Subtitle>
          Are Woosa products suitable for Singapore&apos;s climate?
        </Subtitle>
        <Paragraph>
          Understanding the unique demands of Singapore&apos;s climate, Woosa
          goes beyond just mattresses. We curate a full sleep experience using
          materials that enhance breathability and comfort in the heat. Our
          mattresses feature breathable foams that regulate temperature, while
          our pillows and silk duvets are tailored to keep you cool and
          comfortable through the night. With plush pillows wrapped in
          Italian-made microtencel fabric for extra cool comfort and luxuriously
          soft silk duvets filled with 100% natural, sustainable and ultra-long
          Mulberry silk, our climate-conscious design doesn&apos;t just make us
          a typical mattress store in Singapore. It establishes Woosa as a brand
          dedicated to delivering sleep solutions that are specifically tailored
          to your comfort. With Woosa, you don&apos;t just{' '}
          <Link to={SiteUrls.ComparisonMattress}>buy a mattress</Link>; you
          invest in a tailored, invigorating, and enduring sleep experience
          designed for Singapore&apos;s climate. We invite you to buy a mattress
          online in Singapore from Woosa or visit our mattress showroom in
          Singapore to experience our curated sleep solutions firsthand.
          <br />
          <br />
          Your quest for a perfect night&apos;s sleep starts with choosing
          Woosa, where quality comfort meets affordable luxury.
        </Paragraph>
      </>
    ),
  },
];

const HomeAccordionSection: React.FC = () => {
  return <AccordionSection data={data} initCollapseAll />;
};

export default HomeAccordionSection;
