import React from 'react';
import { PageProps } from 'gatsby';

import { Layout } from '../components/layouts';
import { Link, SEO } from '../components/common';
import {
  CompareMattressesSection,
  HomeLateralSection,
  HowAreWeDifferentSection,
  WoosaPromiseIconGridSection,
  AppointmentSection,
  MeetOurHappySleepersSection,
  HomeControllerSection,
  HomeHeroSection,
  HomeUpsellAdjustableBase2Section,
  HomeUpsellGridSection,
  HomeAdsBannerSection,
  HomePartnerReviewSection,
} from '../components/sections';
import HomeAccordionSection from '../components/sections/home-accordion-section';
import { SiteUrls } from '../utils/constants';

const IndexPage = ({ location }: PageProps): JSX.Element => {
  if (location.hostname === 'support.woosa.sg') {
    window.location.replace('https://woosa.sg/support');
    return <div />;
  } else {
    return (
      <Layout
        announcement={
          <>
            We&apos;ve updated our{' '}
            <Link to={SiteUrls.FreeTrial}>Woosa Sleep Trial</Link> &{' '}
            <Link to={SiteUrls.Shipping}>Shipping</Link> T&C as of 1st November
            2023.
          </>
        }
      >
        <SEO
          title="Woosa: Buy Mattress Singapore - Mattress Store & Showroom"
          titleTemplate=""
          description="Sleep better with Woosa’s mattress designed for Singaporeans. Visit our showroom and mattress shop in Singapore. 10 Year warranty, free trial and shipping."
          meta={[
            {
              name: 'keywords',
              content:
                'buy mattress singapore, mattress store singapore, mattress shop singapore, bed and mattress shops, buy mattress online singapore, mattress showroom, mattress showroom singapore',
            },
          ]}
        />
        <HomeHeroSection />
        <CompareMattressesSection />
        <HomeUpsellAdjustableBase2Section />
        <HomeAdsBannerSection />
        <HomePartnerReviewSection />
        <HowAreWeDifferentSection />
        <WoosaPromiseIconGridSection version="v2" />
        <AppointmentSection />
        <HomeUpsellGridSection />
        <MeetOurHappySleepersSection />
        <HomeAccordionSection />
        <HomeLateralSection />
        <HomeControllerSection />
      </Layout>
    );
  }
};

export default IndexPage;
